#components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
    background: #fff;
}

.site-layout .site-layout-background {
    background-color: #fff;
}

.ant-layout .ant-layout-header {
    line-height: 40px;
}

.ant-layout {
    min-height: 100vh;
}

.ant-layout-header {
    padding: 0 20px;
}
.ant-layout-header .trigger {
    padding: 20px;
}

.ant-layout-sider {
    background: #fff;
}

.cursor-pointer {
    cursor: pointer;
}
