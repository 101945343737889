body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

#root {
    min-height: 100vh;
}

.main-header {
    position: sticky;
    top: 0;
    z-index: 1000;
}

.main-header.nav-scrolled {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transition: 2s;
}

.main-header:not(.nav-scrolled) {
    box-shadow: none;
    transition: 0.5s;
}
